:root {
  --background-colour-main: #070707;
  --accent-colour-main: #D5A33C;
}

body {
  margin: 0;
  font-family: 'Granaina', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-colour-main);
  color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Donau';
  font-style: normal;
  font-weight: normal;
  src: url('./media/fonts/donau.otf');
}

@font-face {
  font-family: 'Granaina';
  font-style: normal;
  font-weight: normal;
  src: url('./media/fonts/granaina.ttf');
}

@font-face {
  font-family: 'Picture House';
  font-style: normal;
  font-weight: normal;
  src: url('./media/fonts/picturehouse.ttf');
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: 'Donau';
  font-weight: 10;
}

p {
  letter-spacing: 1px;
  font-size: 22px;
  line-height: 30px;
  color: #bbbbbb;
}

a {
  color: var(--accent-colour-main);
}

button {
  font-family: 'Granaina';
  font-size: 22px;
  cursor: pointer;
}

hr {
  width: 85%;
  max-width: 1040px;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: none;
  background-color: var(--accent-colour-main);
}