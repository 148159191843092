/** NARROW NAVIGATION MENU */

.narrow-nav-menu-container {
  width: 100%;
  background-color: #00000099;
  height: 800px;
  position: fixed;
  display: flex;
  justify-content: center;
}

.narrow-nav-menu {
  margin-top: 250px;
  height: 165px;
  width: 75%;
  max-width: 400px;
  background-color: #000000;
  border: 1px solid var(--accent-colour-main);
  border-radius: 2px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.narrow-nav-menu > button {
  border: none;
  color: #eeeeee;
  height: 80%;
  background-color: #00000000;
  transition: 0.3s;
  padding: 10px;

  animation-name: header-button-set-anim;
  animation-duration: 1s;
  animation-fill-mode: backwards;
}





/** HOME PAGE */

.home {
  background-image: url('./media/backgrounds/wide-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
}

.home-page-container {
  padding-top: 50px;
  background-color: var(--background-colour-main);
  box-shadow: 0px -20px 15px #00000055;
}

.home-text-logo, .home-text-logo-narrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.home-text-logo {
  height: 100vh;
}

.home-text-logo-narrow {
  z-index: -1;
  height: 630px;
}

.home-text-logo > img {
  width: 500px;
}





/** GUILD PAGE */

.guild-member-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 85%;
}

.guild-member {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.guild-member-narrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.guild-member-headshot {
  border-radius: 50%;
  width: 175px;
  height: 175px;
}

.guild-member > .guild-member-details {
  width: calc(100% - 195px);
  align-items: flex-start;
}

.guild-member-narrow > .guild-member-details {
  width: 100%;
  text-align: center;
}

.guild-member-details {
  display: flex;
  flex-direction: column;
}

.guild-member-name {
  margin-top: 15px;
  margin-bottom: 0;
}

.guild-member-role {
  margin: 0;
  font-size: 18px;
  letter-spacing: 2px;
}

.guild-member-bio {
  font-size: 20px;
  text-align: start;
}





/** PAGE SECTION */

.page-container, .home-page-container, .footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-section, .footer {
  width: 90%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
}

.page-section-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-section-title {
  font-size: 50px;
  letter-spacing: 5px;
}

.page-section-image {
  border-radius: 3px;
  box-shadow: 2px 2px 2px #00000022;
  z-index: 0;
}





/** Show Cards */

.show-card-container {
  width: 75%;
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.show-card {
  text-align: center;
  width: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.show-card > * {
  transition: 0.3s;
  transition-timing-function: ease-out;
}

.show-card > img {
  width: 200px;
  z-index: -0.5;
  margin-bottom: 15px;
  border-radius: 5px;
}

.show-card:hover > img {
  filter: drop-shadow(0px 0px 5px #ffffff) brightness(0.25);
}

.show-card:hover > .show-card-details {
  opacity: 1;
}

.show-card:hover > .show-card-top-title, .show-card:hover > .show-card-main-title, .show-card:hover > .show-card-venue {
  color: var(--accent-colour-main);
  scale: 1.05;
}

.show-card-main-title {
  margin-top: -5px;
  margin-bottom: 0;
}

.show-card-venue {
  margin-top: -5px;
  font-size: 18px;
}

.show-card-click-for-more {
  margin-top: -5px;
  font-size: 12px;
  letter-spacing: 3px;
}

.show-card-details {
  margin-top: -130px;
  z-index: 1;
  opacity: 0;
}





/** Project */

.project-container, .project-container-narrow {
  width: 100%;
  background-color: #00000099;
  display: flex;
  justify-content: center;
}

.project-container {
  position: fixed;
  height: 100vh;
  z-index: 2;
}

.project-container > .project {
  width: 95%;
  max-width: 75vh;
  margin-top: 50px;
  height: 90vh;
  border-radius: 5px;
  border: 1px solid var(--accent-colour-main);
}

.project-container-narrow > .project {
  width: 100%;
  margin-top: 100px;
}

/* The syntax '*:not(img)' is all objects that aren't of the img type */
.project-container-narrow > .project > *:not(img) {
  animation-name: mobile-project-page-anim;
  animation-duration: 0.5s;
}

@keyframes mobile-project-page-anim {
  from {
    transform: translateY(20px);
    opacity: 0
  }
  to {
    transform: translateY(0);
    opacity: 1
  }
}

.project {
  background-color: var(--background-colour-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
}

.project::-webkit-scrollbar {
  display: none;
}

.project > .project-header-image {
  margin: 0;
  width: 100%;
}

.project > * {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.project > hr, .project > * > hr {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #252525;
  border: 1px solid #252525;
}

.project-title {
  font-family: 'Picture House';
  margin-bottom: 0;
}

.project-data {
  font-family: 'Picture House';
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.project-action {
  background-color: var(--accent-colour-main);
  color: var(--background-colour-main);
  border: 1px solid var(--background-colour-main);
  font-family: 'Picture House';
  padding-top: 5px;
  padding-bottom: 5px;
}

.project-action:hover {
  filter: brightness(0.9);
}

.project-tagline {
  font-family: 'Picture House';
  text-align: center;
  width: 85%;
  margin-bottom: 35px;
}

.project-description {
  font-size: 20px;
  margin-top: 0;
}

.project-production-details {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #151515;
  padding: 2px;
  letter-spacing: 1px;
  color: #cccccc;
  padding: 15px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.project-production-detail {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.project-production-detail-label {
  min-width: 15%;
}

.project-production-detail-value {
  color: var(--accent-colour-main);
}

.project-audience-review {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #151515;
  padding: 2px;
  letter-spacing: 1px;
  text-align: center;
  color: #cccccc;
}

.project-audience-review:hover {
  background-color: #101010;
}

.project-company-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 25px;
  width: 100%;
}

.project-company-group-label {
  font-family: 'Picture House';
  font-size: 18px;
  margin-bottom: 5px;
}

.project-company-member {
  width: 100%;
  display: flex;
  gap: 20px;
}

.project-company-member-role > div {
  margin-bottom: 2px;
}

.project-company-member-name {
  text-align: end;
  width: calc(50% - 10px);
  color: #999999;
}

.project-company-member-role {
  text-align: start;
  width: calc(50% - 10px);
  color: var(--accent-colour-main);
}





/** HEADER */

.header, .header-transparent {
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  padding: 10px;
  user-select: none;
  z-index: 1;

  transition: 0.3s;
  display: flex;
  justify-content: space-between;
}

.header > div, .header-transparent > div {
  display: flex;
  align-items: center;
  gap: 15px;

  transition: 0.3s;
}

.header {
  background-color: var(--background-colour-main);
  border-bottom: 1px solid var(--accent-colour-main);
}

.header-transparent {
  border-bottom: 1px solid #d5a34700;
}

.header-logo, .header-logo-transparent {
  transition: 0.3s;
}

.header-logo-transparent {
  scale: 2;
  opacity: 0;
}

.header-logo {
  scale: 1;
  opacity: 1;
}

.header-title {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 9px;
  transition: 0.3s;
}

.header-title > *, .header-title-narrow > * {
  font-family: 'Donau';
  font-weight: 20;
  transition: 0.1s;
  transition-timing-function: cubic-bezier(0.25, 0.7, 0.9, 1);
}

.header > * {
  letter-spacing: 4px;
  color: #ffffff;
}

.header-transparent > * {
  letter-spacing: 0px;
  color: #ffffff00;
}

.header-button-set, .header-button-set-narrow {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-button-set {
  min-width: 280px;
  margin-right: 55px;
  justify-content: space-between;
}

.header-button-set-narrow {
  margin-right: 20px;
}

.header-button-set > button, .header-button-set-narrow > button {
  border: none;
  color: #eeeeee;
  height: 80%;
  background-color: #00000000;
  transition: 0.3s;

  animation-name: header-button-set-anim;
  animation-duration: 1s;
  animation-fill-mode: backwards;
}

.header-button-set > button:hover {
  color: var(--accent-colour-main);
}

@keyframes header-button-set-anim {
  from {opacity: 0;}
  to {opacity: 1;}
}




/** STROOPWAFEL */
.stroopwafel-container-open {
  width: 100%;
  background-color: #00000099;
  height: 1000px;
  position: fixed;
  bottom: 0;
}

.stroopwafel-open, .stroopwafel-closed {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  z-index: 1;
}

.stroopwafel-closed > h2, .stroopwafel-open > h2 {
  font-weight: 600;
  font-family: 'Donau';
}

.stroopwafel-closed {
  height: 40px;
  justify-content: center;
  cursor: pointer;

  background-color: var(--accent-colour-main);
  border-top: 2px solid var(--background-colour-main);
  color: var(--background-colour-main);
}

.stroopwafel-closed:hover {
  filter: brightness(0.75);
}

.stroopwafel-open {
  height: 340px;
  background-color: var(--background-colour-main);
  border-top: 1px solid var(--accent-colour-main);
}

.stroopwafel-open-options {
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stroopwafel-open-options > p {
  font-size: 18px;
  margin-bottom: 0;
}

.stroopwafel-option-button {
  width: 80%;
  padding: 15px;
  border: 1px solid var(--accent-colour-main);
  margin-bottom: 20px;
  text-decoration: none;
  transition: 0.1s;
}

.stroopwafel-option-button:hover {
  background-color: var(--accent-colour-main);
}

.stroopwafel-option-button:hover > p {
  color: var(--background-colour-main);
}

.stroopwafel-option-button > p {
  font-size: 20px;
  margin: 0;
  color: '#ffffff';
  transition: 0.1s;
  font-family: 'Picture House';
  font-weight: 100;
}





/** Collapsible */

.collapsible-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.collapsible-header {
  height: 45px;
  border: 1px solid #303030;
  text-align: start;
  background-color: #202020;
  color: white;
}

.collapsible-header:hover {
  filter: brightness(1.2);
}

.collapsible-area-closed, .collapsible-area-open {
  overflow: hidden;
  transition: 0.5s;
  border: solid #303030
}

.collapsible-area-closed {
  height: 0;
  border-width: 0px;
}

.collapsible-area-open {
  border: 1px solid #303030ff;
  border-width: 1px;
  border-top: none;
}

.collapsible-area-content {
  margin-left: 10px;
  margin-right: 10px;
}





/** IMAGE SLIDER */

.image-slider-container {
  width: 100%;
}

.image-slider-selected {
  width: 100%;
}

.image-slider-selected > img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.image-slider-gallery {
  width: calc(100% - 10px);
  height: 80px;
  padding: 5px;
  background-color: #101010;
  display: flex;
  gap: 3px;
  overflow-x: scroll;
  flex: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.image-slider-gallery::-webkit-scrollbar {
  background-color: #252525;
  height: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.image-slider-gallery::-webkit-scrollbar-thumb {
  background-color: #505050;
  border-radius: 5px;
  transition: 0.2s;
}

.image-slider-gallery::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-colour-main);
}

.image-slider-gallery-image {
  filter: brightness(0.65);
  border-radius: 2px;
  border: 1px solid;
  transition: 0.05s;
}

.image-slider-gallery-image:hover {
  filter: brightness(1);
  cursor: pointer;
}





/** MISCELLANEOUS */

.sub-title-text {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 40px;
  color: #777777;
}

.transparent-button {
  background-color: #00000000;
  font-size: 25px;
  letter-spacing: 1px;
  color: var(--accent-colour-main);
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  transition: 0.5s;
  padding: 0;
}

.transparent-button:hover {
  color: #ffffff;
  gap: 20px;
}

.footer-text {
  font-size: 12px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}